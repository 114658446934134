export default {
  methods: {
    ckLogin() {
      if (
        sessionStorage.getItem("host_id") == null ||
        sessionStorage.getItem("period") == null
      ) {
        this.$router.push("/");
      }
    },
    getFullPeriodThai(p_Date) {
      const DateArray = p_Date.split("-");
      let Y = parseInt(DateArray[0]) + 543;
      let M = DateArray[1];
      let D = DateArray[2];
      return D + " " + this.getMonthFullTH(M - 1) + " " + Y;
    },
    getAbbrPeriodThai(p_Date) {
      const DateArray = p_Date.split("-");
      let Y = parseInt(DateArray[0]) + 543;
      let M = DateArray[1];
      let D = DateArray[2];
      return (
        D + " " + this.getMonthAbbrTH(M - 1) + " " + String(Y).substring(2, 4)
      );
    },
    getAbbrFullYearPeriodThai(p_Date) {
      const DateArray = p_Date.split("-");
      let Y = parseInt(DateArray[0]) + 543;
      let M = DateArray[1];
      let D = DateArray[2];
      return D + " " + this.getMonthAbbrTH(M - 1) + " " + Y;
    },
    getAbbrFullMonthPeriodThai(p_Date) {
      const DateArray = p_Date.split("-");
      let Y = parseInt(DateArray[0]) + 543;
      let M = DateArray[1];
      let D = DateArray[2];
      return (
        D + " " + this.getMonthFullTH(M - 1) + " " + String(Y).substring(2, 4)
      );
    },
    getMonthFullTH(p_numMonth) {
      const MonthTH = [
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ];
      return MonthTH[parseInt(p_numMonth)];
    },
    getMonthAbbrTH(p_numMonth) {
      const MonthTH = [
        "ม.ค.",
        "ก.พ.",
        "มี.ค.",
        "เม.ย.",
        "พ.ค.",
        "มิ.ย.",
        "ก.ค.",
        "ส.ค.",
        "ก.ย.",
        "ต.ค.",
        "พ.ย.",
        "ธ.ค.",
      ];
      return MonthTH[parseInt(p_numMonth)];
    },
    getDT(DT) {
      if (DT == null) return "";
      if (DT == "0000-00-00 00:00:00") return "";
      let TIME = DT.split(" ");
      let DATE = TIME[0].split("-");
      return DATE[2] + "-" + TIME[1];
    },
    getDTShort(DT) {
      if (DT == null) return "";
      if (DT == "0000-00-00 00:00:00") return "";
      let TIME = DT.split(" ");
      let DATE = TIME[0].split("-");
      return (
        DATE[2] + "-" + TIME[1].split(":")[0] + ":" + TIME[1].split(":")[1]
      );
    },
    add_comma(Number) {
      if (Number == null) return "";
      return Number.toString().replace(/\B(?!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    },
    add_comma_empty(Number) {
      if (Number == null) return "";
      if (Number.toString() == "0") return "";
      return Number.toString().replace(/\B(?!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    },
    isEqualSwapNumber(Number1, Number2) {
      const stringPermutations = (str) => {
        if (str.length <= 2)
          return str.length === 2 ? [str, str[1] + str[0]] : [str];
        return str
          .split("")
          .reduce(
            (acc, letter, i) =>
              acc.concat(
                stringPermutations(str.slice(0, i) + str.slice(i + 1)).map(
                  (val) => letter + val
                )
              ),
            []
          );
      };

      const N2 = stringPermutations(Number2);
      for (let n = 0; n < N2.length; n++) {
        if (Number1 == N2[n]) {
          return true;
        }
      }

      return false;
    },
    Permutations(Number) {
      const permute = (str = "") => {
        if (!!str.length && str.length < 2) {
          return str;
        }
        const arr = [];
        for (let i = 0; i < str.length; i++) {
          let char = str[i];
          if (str.indexOf(char) != i) continue;
          let remainder = str.slice(0, i) + str.slice(i + 1, str.length);
          for (let permutation of permute(remainder)) {
            arr.push(char + permutation);
          }
        }
        return arr;
      };
      return permute(Number);
    },
    isTouchScreendevice() {
      return (
        "ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
      );
    },
  },
};
